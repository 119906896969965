import { Checkmark } from "react-checkmark";
import "./StatusOverlay.scss";

function Message({ message }) {
    if (!message) return null;
    return (
        <div className="message">
            {message}
        </div>
    )
}

function Icon({ status }) {
    if (status === "success") {
        return (
            <div className="icon">
                <Checkmark size="80px" />
            </div>
        )
    }
    return null;
}

const DEFAULT_TIMEOUT = 1500;

/**
 * Render a status overlay with an optional message.
 * @param {object} config - Whether to show the overlay. If not null, the contents are used to configure the display.
 * @returns {JSX.Element | null}
 */
export function StatusOverlay({ config }) {

    if (!config) return null;

    if(config.status === "success") {
        setTimeout(config.close, config.timeout || DEFAULT_TIMEOUT)
        return (
            <div className="status-overlay">
                <Icon status="success" />
                <Message message={config.message || config.status} />
            </div>
        )
    }
}
