import { React } from 'react';
import { Card, Form} from 'react-bootstrap';
import { LabeledInput } from './LabeledInput';
import { LabeledSelect } from './LabeledSelect';
import { LabeledDatePicker } from './LabeledDatePicker';
import 'bootstrap/dist/css/bootstrap.min.css'

import './TubeEditorForm.scss'


/**
 * Renders a form for editing tube information.
 *
 * @component
 * @param {Object} props.tube - The tube data
 * @param {Function} props.setTube - The function to update the tube data
 * @param {Boolean} props.disableInputs - Whether the inputs should be disabled
 * @param {Function} props.onFormSubmit - The function to call when the form is submitted
 * @returns {JSX.Element} The rendered TubeEditorForm component.
 */
function TubeEditorForm({ 
    tube, setTube,
    disableInputs=false,
    tubeTypes=[],
    locations=[],
    onFormSubmit,
}) {

    function updateTube(newTube) {
        setTube({
            ...tube,
            shortName: newTube.shortName,
            fullName: newTube.fullName,
            orderCode: newTube.orderCode,
            producer: newTube.producer,
        });
    };

    function setTubeProperty(property, val) {
        // select new tube type by the given property
        let newTubeType = tubeTypes.find(type => type[property] === val);
        if (!newTubeType) {
            return;
        }
        // update the tube data
        updateTube(newTubeType);
    };

    const setShortName = (name) => setTubeProperty('shortName', name);
    const setFullName = (name) => setTubeProperty('shortName', name);
    const setOrderCode = (name) => setTubeProperty('shortName', name);

    function setLocation(value) {
        setTube({
            ...tube,
            location: value,
        });
    }

    function setBatch(val) {
        setTube({
            ...tube,
            batch: val,
        });
    }

    function setOrderDate(val) {
        setTube({
            ...tube,
            orderDate: val,
        });
    }

    function setInventarizationDate(val) {
        setTube({
            ...tube,
            inventarizationDate: val,
        });
    }

    function setExpirationDate(val) {
        setTube({
            ...tube,
            expirationDate: val,
        });
    }

    function setRemovalDate(val) {
        setTube({
            ...tube,
            removalDate: val,
        });
    }

    function fieldSelectOptions(field) {
        return tubeTypes.map(type => {return {
            key: type.shortName,
            value: type[field],
            label: type[field],
        }});
    }

    function producerSelectOptions() {
        return [
            ...new Set(tubeTypes.map(type => {
                return {
                    key: type.producer,
                    value: type.producer, 
                    label: type.producer,
                }
            }))];
    }

    function locationSelectOptions() {
        return locations.map(location => {return {
            key: location.name,
            value: location.name,
            label: location.name,
        }});
    }

    return (
    <div className='tube-editor-form'>
        <Form onSubmit={onFormSubmit}>
            <Card className='form-group'>
                <Card.Header className='small'>Eigenschaften</Card.Header>
                <Card.Body>
                    <LabeledInput
                        id='tube-id-form'
                        label='Prüfröhrchen ID'
                        value={tube.id}
                        disabled
                    />
                    <LabeledSelect
                        id='short-name-form'
                        label='Kurzbezeichnung' 
                        onChange={setShortName}
                        value={tube.shortName}
                        options={fieldSelectOptions('shortName')}
                        disabled={disableInputs}
                    />
                    <LabeledSelect
                        id='full-name-form'
                        label='Bezeichnung'
                        onChange={setFullName}
                        value={tube.shortName}
                        options={fieldSelectOptions('fullName')}
                        disabled={disableInputs}
                    />
                    <LabeledSelect
                        id='order-code-form'
                        label='Bestellnummer'
                        onChange={setOrderCode}
                        value={tube.shortName}
                        options={fieldSelectOptions('orderCode')}
                        disabled={disableInputs}
                    />
                    <LabeledSelect
                        id='producer-form'
                        label='Hersteller'
                        value={tube.producer}
                        options={producerSelectOptions()}
                        disabled={true}
                    />
                    <LabeledSelect
                        id='location-form'
                        label='Ort'
                        onChange={setLocation}
                        value={tube.location}
                        options={locationSelectOptions()}
                        disabled={disableInputs}
                    />
                    <LabeledInput
                        id='batch-form'
                        label='Charge'
                        onChange={setBatch}
                        value={tube.batch}
                        disabled={disableInputs}
                    />
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className='small'>Nachverfolgung</Card.Header>
                <Card.Body>
                    <LabeledDatePicker
                        id='order-date-form'
                        label='Bestelldatum'
                        onChange={setOrderDate}
                        value={tube.orderDate}
                        disabled={disableInputs}
                    />
                    <LabeledDatePicker
                        id='inv-date-form'
                        label='Inventarisierungsdatum'
                        onChange={setInventarizationDate}
                        value={tube.inventarizationDate}
                        disabled={disableInputs}
                    />
                    <LabeledDatePicker
                        id='expiration-date-form'
                        label='Ablaufdatum'
                        onChange={setExpirationDate}
                        value={tube.expirationDate}
                        disabled={disableInputs}
                    />
                    <LabeledDatePicker
                        id='removal-date-form'
                        label='Aussonderungsdatum'
                        onChange={setRemovalDate}
                        value={tube.removalDate}
                        disabled={disableInputs}
                    />
                </Card.Body> 
            </Card>
        </Form>
    </div>
    );
}

export default TubeEditorForm
