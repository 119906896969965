import { Button, Card } from 'react-bootstrap';

import { ExpandableContextTable } from './ExpandableContextTable';

import './WishlistTable.scss';

function renderField (column, row) {
    if ('renderFunc' in column) {
        return column.renderFunc(row[column.accessorKey]);
    }
    return row[column.accessorKey];
}

function ContextMenuButton({show, label, variant, onClick}) {
    if (show) {
        return <Button size='sm' variant={variant} onClick={onClick}>{label}</Button>
    }
    return null;
}

function ContextMenu({wishId, showShow, onShow, showCommit, onCommit, showRemove, onRemove}) {
    return (
        <Card>
            <Card.Body>
                <div className='context-menu'>
                    <ContextMenuButton onClick={() => onShow(wishId)} label='Zeigen' variant='outline-success' show={showShow} />
                    <ContextMenuButton onClick={() => onCommit(wishId)} label='Vorgang abschließen' variant='outline-warning' show={showCommit} />
                    <ContextMenuButton onClick={() => onRemove(wishId)} label='Aus Bestellliste entfernen' variant='outline-danger' show={showRemove} />
                </div>
            </Card.Body>
        </Card>
    );
}

function WishlistTableRow({ row, columns, onRowClicked, rowExpanded, handlers, permissions }) {
    return (<>
        <tr key={row.id} onClick={() => onRowClicked(row.id)}>
            {columns.map((column) => (
                <td key={column.id + '.' + row.id} data-testid={'cell-' + column.id}>
                    { renderField(column, row) }
                </td>
            ))}
        </tr>
        <tr style={{display: rowExpanded ? 'table-row' : 'none'}}>
            <td colSpan={columns.length}>
                <ContextMenu 
                    wishId={row.id} 
                    showShow={permissions.show} 
                    onShow={handlers.showClicked}
                    showCommit={permissions.commit}
                    onCommit={handlers.commitClicked}
                    showRemove={permissions.remove}
                    onRemove={handlers.removeClicked}
                />
            </td>
        </tr>
    </>);
}

export function WishlistTable(
    { 
        columns, items, requestWishlistUpdate, 
        onShow, onCommit, onRemove,
        showShow, showCommit, showRemove,
    }) {
    function createContextRow({row, columns, onRowClicked, rowExpanded}) {
        return <WishlistTableRow 
            row={row}
            columns={columns}
            onRowClicked={onRowClicked}
            rowExpanded={rowExpanded}
            handlers={{
                showClicked: onShow,
                commitClicked: onCommit,
                removeClicked: onRemove,
            }}
            permissions={{
                show: showShow,
                commit: showCommit,
                remove: showRemove,
            }}
            key={row.id}
        />;
    }    
    return (<>
        <ExpandableContextTable
            columns={columns}
            items={items}
            contextRowFactory={createContextRow}
        />
    </>)
}
