import './InfoBar.scss';

/**
 * Renders an information bar component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether to show the information bar.
 * @param {string} props.text - The text to display in the information bar.
 * @param {string} props.title - The title of the information bar.
 * @param {string} props.color - The background color of the information bar.
 * @param {string} props.icon - The icon to display in the information bar.
 * @returns {JSX.Element|null} The rendered information bar component or null if show is false.
 */
function InfoBar({show, text, title, color, icon}) {
    if (show !== true) return null;
    return (
        <div className='infobar' style={{background: color, color: "#FFF"}}>
            <div className='infobar-title'>{title !== '' ? title : 'Information'}</div>
            <div className='infobar-text'>{text}</div>
        </div>
    )
}

export default InfoBar;