import { useEffect, useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'


import auth from '../util/auth';
import Header from '../components/header';
import './Detail.scss'
import TubeEditorForm from '../components/TubeEditorForm';
import ContextMenu from '../components/ContextMenu';
import InfoBar from '../components/InfoBar';
import CommentPanel from '../components/CommentPanel';
import * as api from '../util/api';
import { getLocations } from '../util/globals';
import { RemoveTubeModal } from '../components/RemoveTubeModal';
import { StatusOverlay } from '../components/StatusOverlay';
import Footer from '../components/Footer';


function ActionPanel({expanded, onExpand, tubeId, onEdit, onRemove, onReorder, isTubeEditor, isWishlistEditor}) {        
    return (
        <div className='action-container'>
            <Button variant='outline-secondary' onClick={onExpand} className='action-button'>
                Aktionen
            </Button>
            <Collapse in={expanded}>
                <div>
                    <ContextMenu 
                        tubeId={tubeId} 
                        showShow={false} 
                        onShow={null}
                        showReorder={isWishlistEditor && tubeId}
                        onReorder={() => onReorder(tubeId)}
                        showEdit={isTubeEditor && tubeId}
                        onEdit={() => onEdit(tubeId)}
                        showRemove={isTubeEditor && tubeId}
                        onRemove={() => onRemove(tubeId)}
                    />
                </div>
            </Collapse>
        </div>
    )
}

function DetailForm({show, tube, setTube, tubeTypes, locations}) {

    if (!show) return;

    return (
    <div className='detail-form'>
        <TubeEditorForm 
            tube={tube} 
            setTube={setTube} 
            disableInputs={true} 
            tubeTypes={tubeTypes} 
            locations={locations}
        />
    </div>
    )
}

function formatDate(date) {
    return date.toLocaleString('de-DE', { dateStyle: 'short', timeStyle: 'short' });
}

function RemovalInfoHeader({tube}) {

    if (tube.removalDate && !tube.replacedBy) {
        return <InfoBar 
            show={true} 
            text={`Am ${formatDate(tube.removalDate)} entfernt`} 
            title="Prüfröhrchen nicht vorhanden" 
            color="#666" 
        />
    }
    if (tube.removalDate && tube.replacedBy) {
        return <InfoBar 
            show={true} 
            text={<>Am {formatDate(tube.removalDate)} entfernt und durch <a href={'/#/show/' + tube.replacedBy}>#{tube.replacedBy}</a> ersetzt</>}
            title="Prüfröhrchen nicht vorhanden" 
            color="#666" 
        />
    }
    if (!tube.removalDate && tube.replacedBy) {
        return <InfoBar 
            show={true} 
            text={<>Ersetzt durch <a href={'/#/show' + tube.replacedBy}>#{tube.replacedBy}</a></>} 
            title="Prüfröhrchen nicht vorhanden" 
            color="#666" 
        />
    }
    return null;
}

/**
 * Detail component for displaying tube details.
 *
 * @component
 * @returns {JSX.Element} Detail component
 */
function Detail() {

    const { id } = useParams();
    const [isTubeReader, setIsTubeReader] = useState(false);
    const [isTubeEditor, setIsTubeEditor] = useState(false);
    const [isWishlistEditor, setIsWishlistEditor] = useState(false);
    const [tube, setTube] = useState({});
    const [infoText, setInfoText] = useState('');
    const [infoTitle, setInfoTitle] = useState('');
    const [actionMenuExpanded, setActionMenuExpanded] = useState(false);
    const [comments, setComments] = useState([]);
    const [locations, setLocations] = useState([]);
    const [tubeTypes, setTubeTypes] = useState([]);
    const [statusOverlay, setStatusOverlay] = useState(null);

    const navigate = useNavigate();
    function onReorder(id) {
        api.addToWishlist(tube)
        .then(() => {
            setStatusOverlay({
                status: 'success',
                message: 'Ok',
                timeout: 1500,
                close: () => setStatusOverlay(null),
            });
        }).catch((e) => {
            // ToDo: Prettier error message
            alert("Failed to add tube to wishlist: " + e.message);
        });
    }
    
    function onEdit(id) {
        navigate('/edit/' + id);
    }
    
    /**
     * Fetch tube data from API and set state.
     * Renders an error message to the InfoBar if the API call fails.
     * @returns {void}
     */
    function getTubeFromApi() {
        api.getTube(id).then((tube) => {
            setTube(tube);
        }).catch((e) => {
            setInfoTitle("Fehler beim Abruf der Daten");
            setInfoText(e.message);
        });
    }

    /**
     * Fetch locations from API and set state.
     */
    function getLocationsFromApi() {
        getLocations().then((locations) => {
            setLocations(locations.map(location => location.name));
        }).catch((e) => {
            setInfoTitle("Fehler beim Abruf der Daten");
            setInfoText(e.message);
        });
    }

    /**
     * Fetch tube types from API and set state
     */
    function getTubeTypesFromApi() {
        api.getTubeTypes()
        .then(setTubeTypes)
        .catch((e) => {
            setInfoTitle("Fehler beim Abruf der Daten");
            setInfoText(e.message);
        });
    }

    /**
     * Fetch comments from API and set state.
     * Renders an error message to the InfoBar if the API call fails.
     * @returns {void}
     */
    function getCommentsFromApi() {
        api.getComments(id)
            .then((comments) => {
                setComments(comments);
            })
            .catch((e) => {
                setInfoTitle("Fehler beim Abruf der Kommentare");
                setInfoText(e.message);
            });
    }

    /**
     * Handler for when a new comment is submitted via the CommentPanel.
     * Makes an API call to add the comment to the database, then updates the comments from the API.
     * @param {string} text text of the comment
     * @returns {void}
     */
    function onAddComment(text) {
        api.addComment(tube.id, text)
            .then((comment) => {
                // update comments from API to get the new comment
                getCommentsFromApi();        
            })
            .catch((e) => {
                setInfoTitle("Fehler beim Speichern des Kommentars");
                setInfoText(e.message);
        });
    };

    const [removeTubeModal, setRemoveTubeModal] = useState(false);

    function showRemoveDialog() {
        setRemoveTubeModal(true);
    }

    function hideRemoveDialog() {
        setRemoveTubeModal(false);
    }

    function removeTube(reason, location) {
        api.removeTube(tube.id, reason, location)
            .then((updatedTube) => {
                hideRemoveDialog();
                setTube(updatedTube);
                navigate('/show');
            })
            .catch((e) => {
                setInfoTitle("Prüfröhrchen konnte nicht entfernt werden");
                setInfoText(e.message);
            });
    }

    useEffect(() => {
        // test for roles
        auth.hasRoleTubeReader().then(setIsTubeReader);
        auth.hasRoleTubeEditor().then(setIsTubeEditor);
        auth.hasRoleWishlistEditor().then(setIsWishlistEditor);

        // fetch tube data from API
        getTubeFromApi();

        // fetch comments from API
        getCommentsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        // fetch locations from API
        getLocationsFromApi();

        // fetch tube types from API
        getTubeTypesFromApi();
    }, []);

    return (
        <div className="show">
            <Header />
            <div className="content">
                <StatusOverlay config={statusOverlay}/>
                <RemoveTubeModal 
                    show={removeTubeModal}
                    locations={locations}
                    close={hideRemoveDialog}
                    onRemove={removeTube}
                />
                <div className='tube-editor-container'>
                    <InfoBar show={infoText!==''} text={infoText} title={infoTitle} color='#C02020' />
                    <RemovalInfoHeader tube={tube} />
                    <DetailForm 
                        show={isTubeReader} 
                        tube={tube} 
                        setTube={setTube} 
                        tubeTypes={tubeTypes} 
                        locations={locations} 
                    />
                    <CommentPanel show={isTubeReader} comments={comments} onAddComment={onAddComment} />
                    <ActionPanel 
                        expanded={actionMenuExpanded} onExpand={() => setActionMenuExpanded(!actionMenuExpanded)} 
                        tubeId={tube.id} onEdit={onEdit} 
                        onRemove={showRemoveDialog} onReorder={onReorder} 
                        isTubeEditor={isTubeEditor} isWishlistEditor={isWishlistEditor}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Detail;
