import { Card, Form } from 'react-bootstrap';

import { LabeledInput } from './LabeledInput';
import './WishlistDetailForm.scss';


function formatReplaces(replacesId, replacesTube) {
    if (replacesId === null) {
        return 'n/a';
    }
    if (replacesTube === null) {
        return replacesId;
    }
    return replacesId + ' (' + replacesTube.shortName + ' in ' + replacesTube.location + ')';
}

function formatDate(date) {
    if (date === null) {
        return 'n/a';
    }
    return date.toLocaleString('de-DE', { dateStyle: 'short', timeStyle: 'short' });
}

export function WishlistDetailForm({ item, replacesTube }) {
    if (!item) {
        return null;
    }
    return (<div className='wishlist-editor-form'>
        <Form>
            <Card className='form-group'>
                <Card.Header className='small'>Bestellwunsch</Card.Header>
                <Card.Body>
                    <LabeledInput
                        id='wishlist-item-id-form'
                        label='Interne ID'
                        value={item.id}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-shortname-form'
                        label='Kurzbezeichnung'
                        value={item.shortName}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-fullname-form'
                        label='Bezeichnung'
                        value={item.fullName}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-location-form'
                        label='Ort'
                        value={item.location}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-producer-form'
                        label='Hersteller'
                        value={item.producer}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-ordercode-form'
                        label='Bestellnummer'
                        value={item.orderCode}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-replaces-form'
                        label='Ersetzt (interne ID)'
                        value={formatReplaces(item.replaces, replacesTube)}
                        disabled
                    />
                </Card.Body>
            </Card>
            <Card>
                <Card.Header className='small'>Nachverfolgung</Card.Header>
                <Card.Body>
                    <LabeledInput
                        id='wishlist-addedby-form'
                        label='Hinzugefügt von'
                        value={item.addedBy}
                        disabled
                    />
                    <LabeledInput
                        id='wishlist-addedat-form'
                        label='Datum'
                        value={formatDate(item.addedOn)}
                        disabled
                    />
                </Card.Body> 
            </Card>
        </Form>
    </div>);
}
