import { useState, useEffect } from 'react';

import { getLocations } from '../util/globals';
import * as auth from '../util/auth';

import Header from '../components/header';
import InfoBar from '../components/InfoBar';
import Footer from '../components/Footer';
import './Frontpage.scss';

/**
 * Renders a menu item
 * @param {*} props 
 * @returns {JSX.Element} The MenuItem component UI.
 */
function MenuItem(props) {
    if (!props.show) {
        return null;
    }
    return (
        <li>
            <a href={props.link}>{props.label}</a>
        </li>
    );
}

/**
 * Renders a menu item for a location with a submenues for each location.
 * @param {*} props 
 * @returns {JSX.Element} The LocationMenuItem component UI.
 */
function LocationMenuItem(props) {
    if (!props.show) {
        return null;
    }
    if (props.expanded === true) {
        return (
            <li>
                <p className="expandableMenu" onClick={props.toggleExpand}>{props.label}</p>
                <ul class="submenu">
                    {
                        props.locations
                            .filter((location) => location !== "-") // '-' is a reserved location for absent tubes
                            .sort()
                            .map((location) => (
                                <MenuItem label={location} link={"/#/location/" + encodeURIComponent(location)} show={true} />
                            ))
                    }
                </ul>
            </li>
        );
    }
    return (
        <li>
            <p href="#" onClick={props.toggleExpand}>{props.label}</p>
        </li>
    );
}

/**
 * Renders the main menu of the frontpage component. Stubbed for now.
 * @returns {JSX.Element} The Menu component UI.
 */
function Menu() {

    const [expandLocations, setExpandLocations] = useState(false);
    const [locationNames, setLocationNames] = useState([]);
    const [isTubeReader, setIsTubeReader] = useState(false);
    const [isTubeEditor, setIsTubeEditor] = useState(false);
    const [isDataExporter, setIsDataExporter] = useState(false);
    const [isWishlistReader, setIsWishlistReader] = useState(false);
    const [error, setError] = useState('');

    // get locations from the API to render the location menu
    useEffect(() => {
        auth.hasRoleTubeReader().then((res) => {
            setIsTubeReader(res);
            getLocations().then((locations) => {
                setLocationNames(locations.map((location) => location.name));
            }).catch(() => {
                setError("You do not have permission to view this page.");
            })
        });
        auth.hasRoleDataExporter().then((res) => {
            setIsDataExporter(res);
        });
        auth.hasRoleTubeEditor().then((res) => {
            setIsTubeEditor(res);
        });
        auth.hasRoleWishlistReader().then((res) => {
            setIsWishlistReader(res);
        });
    }, []);

    // calculate the date in months from now - used for the expires-before menu item
    function inMonths(months) {
        let today = new Date();
        let future = new Date(today.setMonth(today.getMonth() + months));
        let dateStr = future.toISOString().split('T')[0];
        return dateStr;
    }

    return (
        <div className="frontpage-menu">
            <InfoBar show={error !== ''} text={error} title="Error" color="#F00" />
            <ul class='menu'>
                <MenuItem label="Alle anzeigen" link="/#/show/" show={isTubeReader} />
                <LocationMenuItem
                    label="Nach Ort"
                    link="/#/show/"
                    expanded={expandLocations}
                    toggleExpand={() => setExpandLocations(!expandLocations)}
                    locations={locationNames}
                    show={isTubeReader}
                />
                <MenuItem label="Läuft bald ab" link={"/#/expires-before/" + inMonths(3)} show={isTubeReader} />
                <MenuItem label="Neu anlegen" link="/#/new/" show={isTubeEditor} />
                <MenuItem label="Bestellliste" link="/#/wishlist/" show={isWishlistReader} />
                <MenuItem label="Daten exportieren" link="/export/" show={isDataExporter} />
            </ul>
        </div>
    );
}

/**
 * Renders the Frontpage component.
 * @returns {JSX.Element} The Frontpage component UI.
 */
export default function Frontpage() {
    return (
        <div className="frontpage">
            <Header />
            <div className="content">
                <Menu />
            </div>
            <Footer />
        </div>
    );
}
