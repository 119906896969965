import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'


import auth from '../util/auth';
import Header from '../components/header';
import './Detail.scss'
import TubeEditorForm from '../components/TubeEditorForm';
import InfoBar from '../components/InfoBar';
import { getLocations, getTubeTypes } from '../util/globals';
import api from '../util/api';
import Footer from '../components/Footer';


function ButtonPanel({onSubmit, onCancel}) {
    return (
        <div className='button-container'>
            <div className='d-grid gap-2'>
                <Button 
                    className='button' 
                    variant='success' 
                    type='submit' 
                    size='sm' 
                    onClick={onSubmit}>
                        Speichern
                </Button>
                <Button 
                    className='button' 
                    variant='outline-secondary' 
                    type='cancel' 
                    size='sm'
                    onClick={onCancel}>
                        Abbrechen
                </Button>
            </div>
        </div>
    )
}

function EditForm({show, tube, setTube, tubeTypes, locations}) {

    if (!show) return;

    return (
    <>
        <TubeEditorForm tube={tube} setTube={setTube} disableInputs={false} tubeTypes={tubeTypes} locations={locations}/>
    </>
    )
}

/**
 * Detail component for displaying tube details.
 *
 * @component
 * @returns {JSX.Element} Detail component
 */
function Edit() {

    const { id } = useParams();
    const [isTubeEditor, setIsTubeEditor] = useState(false);
    const [tube, setTube] = useState({});
    const [infoText, setInfoText] = useState('');
    const [infoTitle, setInfoTitle] = useState('');
    const [tubeTypes, setTubeTypes] = useState([]);
    const [locations, setLocations] = useState([]);

    const navigate = useNavigate();

    /**
     * When the submit button is pressed: submit the form and navigate to the previous page.
     */
    function onSubmit() {
        api.updateTube(tube).then((response) => {
            navigate('/show');
        }).catch((e) => {
            setInfoTitle("Fehler beim Speichern der Daten");
            setInfoText(e.message);
        });
    }

    /**
     * When the cancel button is pressed: navigate back to the previous page.
     */
    function onCancel() {
        navigate(-1);
    }

    useEffect(() => {
        // test for roles
        auth.hasRoleTubeEditor().then(setIsTubeEditor);

        getTubeTypes().then((types) => {
            setTubeTypes(types);
        }).catch((e) => {
            setInfoTitle("Fehler beim Abruf der Daten");
            setInfoText([infoText, e.message].join(' '));
        });

        getLocations().then((locations) => {
            setLocations(locations);
        }).catch((e) => {
            setInfoTitle("Fehler beim Abruf der Daten");
            setInfoText([infoText, e.message].join(' '));
        });

        // if an id is provided, fetch the tube data
        // else create an empty tube
        if (id) {
            api.getTube(id).then((tube) => {
                setTube(tube);
            }).catch((e) => {
                setInfoTitle("Fehler beim Abruf der Daten");
                setInfoText([infoText, e.message].join(' '));
            });
        } else {
            setTube(api.createEmptyTube());
        }
    }, [id, infoText]);

    return (
        <div className="show">
            <Header />
            <div className="content">
                <div className='tube-editor-container'>
                    <InfoBar show={infoText!==''} text={infoText} title={infoTitle} color='#C02020' />
                    <InfoBar show={!isTubeEditor} text='Sie haben keine Berechtigung, diese Seite zu sehen.' title='Keine Berechtigung' color='#F8F8F8' />
                    <EditForm 
                        show={isTubeEditor} 
                        tube={tube}
                        setTube={setTube} 
                        tubeTypes={tubeTypes} 
                        locations={locations}
                    />
                    <ButtonPanel onSubmit={onSubmit} onCancel={onCancel} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Edit;
