import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Frontpage from './views/Frontpage';
import Show from './views/Show';
import Detail from './views/Detail';
import Edit from './views/Edit';
import { Wishlist } from './views/Wishlist';
import { WishlistDetail } from './views/WishlistDetail';
import { WishlistCommitDialog } from "./views/WishlistCommitDialog";

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Frontpage />} />
                <Route path="/show" element={<Show />} />
                <Route path="/location/:location" element={<Show />} />
                <Route path="/expires-before/:expiresBefore" element={<Show />} />
                <Route path="/show/:id" exact Component={Detail} />
                <Route path="/edit/:id" element={<Edit />} />
                <Route path="/new/" element={<Edit />} />
                <Route path="/wishlist" element={<Wishlist />} />
                <Route path="/wishlist/:id" element={<WishlistDetail />} />
                <Route path="/wishlist/:id/commit" element={<WishlistCommitDialog />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;
