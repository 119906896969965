import { forwardRef } from 'react';
import { FloatingLabel, Form} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


/**
 * Renders a labeled date picker.
 * @param {String} id - The ID of the date picker.
 * @param {String} label - The label of the date picker.
 * @param {Date} value - The value of the date picker.
 * @param {Function} onChange - The function to update the value of the date picker.
 * @param {Boolean} disabled - Whether the date picker is disabled.
 * @returns {JSX.Element} - The rendered LabeledDatePicker component.
 */
export function LabeledDatePicker({id, label, value, onChange, disabled=false}) {

    const InnerFloatingLabelInput = forwardRef(({value, onClick }, ref) => (
        <Form.Group>
            <FloatingLabel label={label} className='mb-3' controlId={id}>
                <Form.Control 
                    type="text" 
                    placeholder=''
                    onClick={onClick}
                    defaultValue={value}
                    disabled={disabled} 
                />
            </FloatingLabel>                     
        </Form.Group>
    ));

    return (
        <Form.Group controlId={id}>
            <DatePicker 
                selected={value} 
                onChange={(date) => onChange(date)} 
                disabled={disabled}
                customInput={<InnerFloatingLabelInput />}
                dateFormat='dd.MM.yyyy'
                calendarStartDay={1}
                showYearDropdown
            />
        </Form.Group>
    );
}
