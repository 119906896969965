import { useEffect, useState } from "react"
import { useParams } from "react-router";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import api from "../util/api";
import { hasRoleWishlistReader, hasRoleTubeReader } from "../util/auth";
import Header from "../components/header";
import InfoBar from "../components/InfoBar";
import { WishlistDetailForm } from "../components/WishlistDetailForm";
import './WishlistDetail.scss';

/**
 * Renders a simple button that on click navigates back to 
 * the previous page.
 * @returns {JSX.Element} The rendered AbortButton component.
 */
function AbortButton() {
    const navigate = useNavigate();
    return (
        <div className='abort-button'>
            <div className="d-grid gap-2">
                <Button className='btn btn-secondary' onClick={() => { navigate(-1)}}>Abbrechen</Button>
            </div>
        </div>
    );
}

/**
 * WishlistDetail component: A component that gives a detailed 
 * view of a single wishlist item.
 * @returns {JSX.Element} The rendered WishlistDetail component.
 */
export function WishlistDetail() {

    const [wishlistItem, setWishlistItem] = useState(null);
    const [replacesTube, setReplacesTube] = useState(null);
    const [error, setError] = useState("");
    const { id } = useParams();

    const idInt = parseInt(id);

    useEffect(() => {
        // there is no 'get' endpoint in the wishlist API
        // so we need to filter through the full wish list
        hasRoleWishlistReader().then((value) => {
            if (value) {
                api.getWishlist()
                .then((response) => {
                    let wlItems = response.filter((item) => item.id === idInt);
                    if (wlItems.length !== 1) {
                        setError(`Wishlist item with id ${id} not found`);
                        return;
                    }
                    setWishlistItem(wlItems[0]);
                    
                    // try to fetch the tube that this item replaces
                    hasRoleTubeReader().then((value) => {
                        if (value) {
                            api.getTube(wlItems[0].replaces)
                            .then((response) => {
                                setReplacesTube(response);
                            })
                            .catch((error) => {
                                setError(`Failed to fetch Tube: ${error}`);
                            });
                        } else {
                            setError("Could not fetch Tube: You do not have permission to read tube data");
                        }
                    });
                })
                .catch((error) => {
                    setError(`Failed to fetch Wishlist: ${error}`);
                });
            } else {
                setError("You are not allowed to view this page");
            }
        });
    }, [id, idInt]);

    return (<>
        <Header />
        <InfoBar show={error !== ""} text={error} title="Fehler" color="#F00"></InfoBar>
        <div className='wishlist-detail-form'>
            <WishlistDetailForm item={wishlistItem} replacesTube={replacesTube} />
            <AbortButton />
        </div>
    </>)
}