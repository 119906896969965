import { Button, Card, FloatingLabel, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'

import './CommentPanel.scss'


function formatDateTime(date) {
    return new Date(date).toLocaleString('de-DE', {dateStyle: 'medium', timeStyle: 'short'}) + ' Uhr';
}

/**
 * Render a list of comments.
 * @param {Array} comments List of comment objects
 * @returns {JSX.Element}
 */
function CommentPanel({comments, onAddComment, show=true}) {

    function onSubmit() {
        // not using states because the API handling is async and the state might be updated to soon
        const newCommentText = document.getElementById('comment-form-input').value;
        onAddComment(newCommentText);
        document.getElementById('comment-form-input').value = '';
    }

    // do not render if not visible
    if (!show) return;

    const commentsDesc = comments.sort((a, b) => {
        return b.date - a.date;
    })

    return (
        <div className='comment-panel'>
            <Card>
                <Card.Header className='small'>
                    Kommentare
                </Card.Header>
                <Card.Body>
                    <div className='comments'>
                        {commentsDesc.map((comment, index) => (
                            <div className='comment' key={index}>
                                <h3 className='title'>{comment.author} am {formatDateTime(comment.date)}</h3>
                                <div className='text'><p>{comment.text}</p></div>
                            </div>
                        ))}
                    </div>
                    <div className='comment-form'>
                        <div className='d-grid gap-2'>
                            <FloatingLabel controlId='comment-form-input' label='Neuer Kommentar'>
                                <Form.Control as='textarea' placeholder='Neuer Kommentar' data-testid='comment-form-input' />
                            </FloatingLabel>
                            <Button 
                                id='comment-form-submit'
                                className='button' 
                                variant='secondary' 
                                type='submit' 
                                size='sm'
                                onClick={onSubmit}>
                                    kommentieren
                            </Button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CommentPanel;