import { create } from 'zustand';
import api from './api.js';

const tubeInfo = create(set => ({
    tubeTypes: [],
    locations: [],
}));

/**
 * Returns the list of tube types given by the API, 
 * or the cached list if it has already been fetched.
 * @returns list[object]
 */
export async function getTubeTypes() {
    if (tubeInfo.getState().tubeTypes.length === 0) {
        const tubeTypes = await api.getTubeTypes();
        tubeInfo.setState({ tubeTypes: tubeTypes });
    }
    return tubeInfo.getState().tubeTypes;
}

/**
 * Returns the list of locations given by the API,
 * or the cached list if it has already been fetched.
 * @returns list[object]
 */
export async function getLocations() {
    if (tubeInfo.getState().locations.length === 0) {
        const locations = await api.getLocations();
        tubeInfo.setState({ locations: locations });
    }
    return tubeInfo.getState().locations;
}

/**
 * Reset the global state of the tube types and locations
 * Primarily for testing purposes
 */
export async function resetGlobals() {
    tubeInfo.setState({ tubeTypes: [], locations: [] });
}