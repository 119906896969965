import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import InfoBar from '../components/InfoBar';
import Header from '../components/header';
import TubeEditorForm from '../components/TubeEditorForm';
import { hasRoleWishlistReader, hasRoleWishlistEditor, hasRoleTubeReader, hasRoleTubeEditor } from '../util/auth';
import api from '../util/api';

import './WishlistCommitDialog.scss';

/**
 * Render a header that informs on which tube is 
 * replaced by the current wishlist item.
 * @param {Object} props - The props object. replacesTube: The tube that is replaced.
 * @returns {JSX.Element} The rendered ReplacementInfoHeader component.
 */
function ReplacementInfoHeader({replacesTube}) {
    if (!replacesTube) {
        return null;
    }
    return (
        <InfoBar
            show={true}
            text={`Ersetzt ${replacesTube.shortName} in ${replacesTube.location}`}
            title="Information"
            color="#ED760E"
        />
    )
}

/**
 * Renders the action buttons under the form
 * @param {*} param0 
 * @returns 
 */
function ActionButtons({onCommit, onCancel}) {
    return (
        <div className="action-buttons">
            <div className="d-grid gap-2">
                <Button type="submit" onClick={onCommit} variant="secondary">Ok</Button>
                <Button onClick={onCancel} variant="outline-secondary">Abbrechen</Button>
            </div>
        </div>
    );
}

export function WishlistCommitDialog() {
    let [wishlistItem, setWishlistItem] = useState(null);
    let [replacesTube, setReplacesTube] = useState(null);
    let [error, setError] = useState("");
    let [isWishlistReader, setIsWishlistReader] = useState(false);
    let [isWishlistEditor, setIsWishlistEditor] = useState(false);
    let [isTubeReader, setIsTubeReader] = useState(false);
    let [isTubeEditor, setIsTubeEditor] = useState(false);
    let [newTube, setNewTube] = useState({});
    let [locations, setLocations] = useState([]);
    let [tubeTypes, setTubeTypes] = useState([]);

    const { id } = useParams();

    // check permissions
    useEffect(() => {
        hasRoleWishlistReader().then(setIsWishlistReader);
        hasRoleWishlistEditor().then(setIsWishlistEditor);
        hasRoleTubeReader().then(setIsTubeReader);
        hasRoleTubeEditor().then(setIsTubeEditor);
    }, []);

    // get the wishlist item from the API
    useEffect(() => {
        // there is no 'get' endpoint in the wishlist API
        // so we need to filter through the full wish list
        if (isWishlistReader) {
            let idInt = parseInt(id);
            api.getWishlist()
            .then((response) => {
                let wlItems = response.filter((item) => item.id === idInt);
                if (wlItems.length !== 1) {
                    setError(`Wishlist item with id ${id} not found`);
                    return;
                }
                setWishlistItem(wlItems[0]);
            })
            .catch((error) => {
                setError(`Failed to fetch Wishlist: ${error}`);
            });
        }
    }, [isWishlistReader, id]);
    
    // get the tube that this item replaces
    // depends on the wishlistItem being ready and 
    // the user having the WishlistReader and TubeReader 
    // permissions
    useEffect(() => {
        if (isTubeReader && wishlistItem) {
            // try to fetch the tube that this item replaces
            api.getTube(wishlistItem.replaces)
            .then((response) => {
                setReplacesTube(response);
            })
            .catch((error) => {
                setError(`Failed to fetch Tube: ${error}`);
            });
        }
    }, [wishlistItem, isWishlistReader, isTubeReader]);

    // fetch locations and tube types for autocomplete
    useEffect(() => {
        if (isTubeReader) {
            api.getLocations()
            .then((response) => {
                setLocations(response);
            })
            .catch((error) => {
                setError(`Failed to fetch Locations: ${error}`);
            });
            api.getTubeTypes()
            .then((response) => {
                setTubeTypes(response);
            })
            .catch((error) => {
                setError(`Failed to fetch Tube Types: ${error}`);
            });
        }
    }, [isTubeReader]);

    useEffect(() => {
        if (wishlistItem) {
            setNewTube({
                id: null,
                shortName: wishlistItem.shortName,
                fullName: wishlistItem.fullName,
                location: wishlistItem.location,
                producer: wishlistItem.producer,
                orderCode: wishlistItem.orderCode,
                batch: '',
                orderDate: null,
                inventarizationDate: new Date(),
                expirationDate: null,
                removalDate: null,
                replacedBy: null,        
            });
        }
    }, [wishlistItem]);

    // TODO: make this configurable
    const LOCATION_FOR_REPLACED_TUBES = "-";

    /**
     * update tubes and wishlist in the API
     * async, because in .then() synthax this would
     * be incredibly nested and messy
     */
    async function asyncUpdateApi() {
        let t1;
        // first, create the new tube
        try {
            t1 = await api.updateTube(newTube);
        } catch (error) {
            throw new Error(`Failed to create the new tube: ${error}`);
        }
        // if there is a tube that is replaced, update it
        if (replacesTube) {
            try {
                await api.updateTube({
                    ...replacesTube, 
                    replacedBy: t1.id, 
                    location: LOCATION_FOR_REPLACED_TUBES,  // move replaced tube to the location for replaced tubes
                    removalDate: replacesTube.removalDate || new Date(), // update removal date if not already set
                });
            } catch (error) {
                throw new Error(`Failed to create the new tube: ${error}`);
            }
            // add explanatory comment
            try {
                await api.addComment(
                    replacesTube.id, 
                    `Am ${new Date().toLocaleDateString('de-DE', {dateFormat: 'short'})} ` +
                    `ersetzt durch Typ ${newTube.shortName} in ${newTube.location} (interne ID ${t1.id})`,
                );    
            } catch (error) {
                throw new Error(`Failed to add comment to replaced tube: ${error}`);
            }
        }
        // then, update the wishlist item
        try {
            await api.removeFromWishlist(wishlistItem.id);
        } catch (error) {
            throw new Error(`Failed to remove the wishlist item: ${error}`);
        }
    }

    function handleSubmit() {
        if (!isWishlistEditor || !isTubeEditor) {
            setError("Sie haben nicht die nötigen Rechte um diesen Vorgang durchzuführen");
            return;
        }
        if (!newTube.shortName || !newTube.fullName || !newTube.location || !newTube.producer || !newTube.orderCode ||
            !newTube.batch || !newTube.inventarizationDate || !newTube.expirationDate) {
            setError("Bitte füllen Sie alle Felder aus");
            return;
        }
        asyncUpdateApi()
        .then(() => {
            navigate(-1);
        })
        .catch((error) => {
            setError(error.message);
        });
    }

    const navigate = useNavigate();

    return (
        <div className="wishlist-commit-dialog">
            <Header />
            <InfoBar show={error !== ""} text={error} title="Fehler" color="#F00" />
            <ReplacementInfoHeader replacesTube={replacesTube} />
            <TubeEditorForm 
                tube={newTube}
                setTube={setNewTube}
                disableInputs={!isTubeEditor}
                tubeTypes={tubeTypes}
                locations={locations}
                onFormSubmit={handleSubmit}
            />
            <ActionButtons onCommit={handleSubmit} onCancel={() => navigate(-1)} />
        </div>
    );
}
