import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Header from '../components/header';
import Footer from '../components/Footer';
import InfoBar from '../components/InfoBar';
import { WishlistTable } from '../components/WishlistTable';
import api from '../util/api';
import { hasRoleWishlistReader, hasRoleWishlistEditor } from '../util/auth';
import './Wishlist.scss';

const COLUMNS = [
    {
        id: 'shortName',
        Header: 'Typ',
        accessorKey: 'shortName',
    }, {
        id: 'fullName',
        Header: 'Bezeichnung',
        accessorKey: 'fullName',
    }, {
        id: 'orderCode',
        Header: 'Bestellnummer',
        accessorKey: 'orderCode',
    }, {
        id: 'location',
        Header: 'Ort',
        accessorKey: 'location',
    }, {
        id: "replaces",
        Header: "Ersetzt",
        accessorKey: "replaces",
        renderFunc: (value) => {
            return <a href={ "/#/show/" + value }>{value}</a>
        },
    }, {
        id: "addedBy",
        Header: "Von",
        accessorKey: "addedBy",
    },
];

export function Wishlist() {
    const [error, setError] = useState('');
    const [wishlist, setWishlist] = useState([]);
    const [isWishlistReader, setIsWishlistReader] = useState(false);
    const [isWishlistEditor, setIsWishlistEditor] = useState(false);

    function updateWishlist() {
        api.getWishlist()
            .then((response) => {
                setWishlist(response);
            })
            .catch((error) => {
                setError(`Failed to fetch Wishlist: ${error}`);
            });
    }

    function removeWishlistItem(wishId) {
        let verify = window.confirm('Wirklich löschen?');
        if (verify) {
            api.removeFromWishlist(wishId)
                .then(() => {
                    updateWishlist();
                })
                .catch((error) => {
                    setError(`Failed to remove item from Wishlist: ${error}`);
                });
        }
    }

    const navigate = useNavigate();

    function showWishlistItem(wishId) {
        navigate('/wishlist/' + wishId);
    }

    function commitWishlistOrder(wishId) {
        navigate('/wishlist/' + wishId + '/commit');
    }

    useEffect(() => {
        hasRoleWishlistReader().then((value) => {
            setIsWishlistReader(value);
            if (value) {
                updateWishlist();
            } else {
                setError('You are not allowed to view this page');
            }
        });
        hasRoleWishlistEditor().then(setIsWishlistEditor);
    }, []);

    return (
        <div className="wishlist">
            <Header />
            <div className="content">
                <InfoBar show={error !== ""} text={error} title="Fehler" color="#F00" />
                <WishlistTable
                    columns={COLUMNS}
                    items={wishlist}
                    requestWishlistUpdate={updateWishlist}
                    onCommit={commitWishlistOrder}
                    onShow={showWishlistItem}
                    onRemove={removeWishlistItem}
                    showShow={isWishlistReader}
                    showCommit={isWishlistEditor}
                    showRemove={isWishlistEditor}
                />
            </div>
            <Footer />
        </div>
    );
}
