import './Footer.scss';

export default function Footer() {
    return (
        <footer>
            <div className="left">
                <a href='https://abc-zug.info'>ABC-Zug M&uuml;nchen-Land</a>
            </div>
            <div className="right">
                <a href='/auth/login/logout'>
                    abmelden
                </a>
            </div>
        </footer>
    );
}
