import { React, useState } from 'react';
import { Button, Collapse, Card, Form } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css'

import './SearchBar.scss'

export default function SearchBar({textFilter, setTextFilter}) {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className='searchbar'>
            <div className='header'>
            <Button onClick={() => setExpanded(!expanded)}
                    aria-controls="bar"
                    aria-expanded={expanded}
                    variant="outline-secondary"
                    size='sm'
                    className='toggle'
            >
                Suche
            </Button>
            <Button
                size='sm'
                variant="outline-secondary"
                className='clear'
                onClick={() => setTextFilter('')}
            >
                x
            </Button>
            </div>
            <Collapse in={expanded} id='bar'>
                <Card>
                    <Card.Body>
                        <Form.Group className='mb-3'>
                            <Form.Control 
                                type='text' 
                                placeholder='Suchtext' 
                                value={textFilter} 
                                onChange={(e) => setTextFilter(e.target.value)}
                            />
                        </Form.Group>
                    </Card.Body>
                </Card>
            </Collapse>
        </div>
    )
}
