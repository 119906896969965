import { Button, Card } from 'react-bootstrap'

import './ContextMenu.scss'

// A button in the ContextMenu that is only shown if the show parameter is true
function ContextMenuButton({show, label, variant, onClick}) {
    if (show) {
        return <Button size='sm' variant={variant} onClick={onClick}>{label}</Button>
    }
    return null;
}

/**
 * Renders the ContextMeu component
**/ 
export default function ContextMenu ({tubeId, onShow, onReorder, onEdit, onRemove, showShow=true, showReorder=true, showEdit=true, showRemove=true}) {
    return (
        <Card>
            <Card.Body>
                <div className='context-menu'>
                    <ContextMenuButton onClick={() => onShow(tubeId)} label='Zeigen' variant='outline-success' show={showShow} />
                    <ContextMenuButton onClick={() => onReorder(tubeId)} label='Nachbestellen' variant='outline-warning' show={showReorder} />
                    <ContextMenuButton onClick={() => onEdit(tubeId)} label='Ändern' variant='outline-warning' show={showEdit} />
                    <ContextMenuButton onClick={() => onRemove(tubeId)} label='Entfernen' variant='outline-danger' show={showRemove} />
                </div>
            </Card.Body>
        </Card>
    )
}
