import { useState } from "react";
import { Button, Card, Form } from "react-bootstrap";

import './RemoveTubeModal.scss';

const REASONS = {
    "verbraucht": {
        "defaultLocation": "-",
        "mutable": false,
    },
    "fehlt": {
        "defaultLocation": "-",
        "mutable": false,
    },
    "ausgesondert": {
        "defaultLocation": "Lager",
        "mutable": false,
    },
    "sonstige": {
        "defaultLocation": "Lager",
        "mutable": true,
    },
};


/**
 * Render a modal that asks for required information for removing a tube. 
 * These information are: the reason for removal and the new location
 * If the user submits the form, reason and location are returned via callback 
 * to the parent component.
 * @param show - bool - Show the modal
 * @param locations - list[str] - List of locations to choose from
 * @param close - function - Callback to close the modal
 * @param onRemove - function - Callback to submit the form
 * @param reasons - dict - List of options for the reasons for removal
 * @param preselectedReason - str - Preselected reason for removal (default: first item in {reasons})
 * @returns JsxElement
 */
export function RemoveTubeModal({
    show,
    locations,
    close,
    onRemove,
    reasons=REASONS,
    preselectedReason=null,
}) {
    // if no preselected reason is given, use the first reason from the list
    preselectedReason = preselectedReason || Object.keys(reasons)[0];
    const [reason, setReason] = useState(preselectedReason);
    const [location, setLocation] = useState(reasons[reason].defaultLocation);

    // update the reason for removal and set the associate location
    function updateReason(e) {
        setReason(e.target.value);
        setLocation(reasons[e.target.value].defaultLocation);
    }

    // update the location
    function updateLocation(e) {
        setLocation(e.target.value);
    }

    // submit the form. Performs a callback to the parent component via the onRemove prop
    function onOk() {
        onRemove(reason, location);
    }

    // If the user aborts the form. Closes the modal
    function onAbort() {
        close();
    }

    if (!show) return null;

    return (
        <div className='remove-tube-container'>
            <div className='modal-overlay'>
                <Card role="dialog" aria-labelledby="remove-dialog-label">
                    <Card.Header>
                        <Card.Title id="remove-dialog-label">Prüfröhrchen entfernen</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Form onSubmit={onOk}>
                            <Form.Group controlId="remove-tube-form">
                                <div className="modal-input">
                                    <Form.Label>Grund</Form.Label>
                                    <Form.Control as="select" value={reason} onChange={updateReason}>
                                        {Object.keys(reasons).map(reason => (
                                            <option key={reason} value={reason}>{reason}</option>
                                        ))}
                                    </Form.Control>
                                </div>
                                <div className="modal-input">
                                <Form.Label>Neuer Ort</Form.Label>
                                <Form.Control as="select" disabled={!reasons[reason].mutable} value={location} onChange={updateLocation}>
                                    {locations.map(location => (
                                        <option key={location} value={location}>{location}</option>
                                    ))}
                                </Form.Control>
                                </div>
                                <div className="button-row">
                                    <Button size="sm" variant="outline-secondary" onClick={onOk}>OK</Button>
                                    <Button size="sm" variant="outline-secondary" onClick={onAbort}>Abbrechen</Button>
                                </div>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};
