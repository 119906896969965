import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';

import sortableIcon from './sortable.svg';
import sortedDescIcon from './sorted-desc.svg';
import sortedAscIcon from './sorted-asc.svg';

import './ExpandableContextTable.scss';

/**
 * Render the tube table header with the sort icon
 * @param {*} column 
 * @param {*} sortKey 
 * @param {*} sortDirection 
 * @returns 
 */
function renderTableHead (column, sortKey, sortDirection) {
    if (column.id !== sortKey) {
        return (
        <> 
            <div className='header-label'>{column.Header}</div>
            <div className='sort-icon'><img src={sortableIcon} alt='sortable'/></div>
        </>
        )
    }
    else if (sortDirection === 'asc') {
        return (
            <> 
                <div className='header-label'>{column.Header}</div>
                <div className='sort-icon'><img src={sortedAscIcon} alt='sorted ascending' /></div>
            </>
            )
        }
    else {
        return (
            <> 
                <div className='header-label'>{column.Header}</div>
                <div className='sort-icon'><img src={sortedDescIcon} alt='sorted descending' /></div>
            </>
            )
        }
}

/**
 * Sort the data by the given key and direction
 * @param {*} tubes
 * @param {*} updateTubes
 * @param {*} key
 * @param {*} direction
 * @returns
 */ 
function sortData (tubes, updateTubes, key, direction) {
    const sorted = [...tubes].sort((a, b) => {
        if (a[key] < b[key]) {
            return direction === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return direction === 'asc' ? 1 : -1;
        }
        return 0;
    });
    updateTubes(sorted);
}

/**
 * Render a component that with a configurable table and 
 * expandable context menu for each row
 * 
 * @param {*} columns: Column definitions
 * @param {*} tubes: List of tubes
 * @param {*} contextRowFactory: Factory to create the context menu component
 * @returns {JSX.Element} ExpandableContextTable
 */
export function ExpandableContextTable({ columns, items, contextRowFactory }) {

    const [sortedData, setSortedData] = useState([]);
    const [sortKey, setSortKey] = useState(columns[0].accessorKey);
    const [sortDirection, setSortDirection] = useState('asc');
    const [expandedRow, setExpandedRow] = useState(null);

    function updateSortConfig(key) {
        let newSortDirection;  // necessary, because setSortKey is async

        if (key === sortKey) {
            newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            setSortDirection(newSortDirection);
        } else {
            newSortDirection = 'asc';
            setSortKey(key);
            setSortDirection(newSortDirection);
        }
        sortData(items, setSortedData, key, newSortDirection);
    }

    function updateExpandedRow (rowId) {
        if (expandedRow === rowId) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowId);
        }
    }
    
    useEffect(() => {
        setSortedData(items);
        sortData(items, setSortedData, sortKey, sortDirection);
    }, [items, sortKey, sortDirection]);

    return (
        <>
            <Table responsive hover role='table'>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <th 
                                key={column.id}
                                onClick={() => {updateSortConfig(column.accessorKey)}}
                            >{renderTableHead(column, sortKey, sortDirection)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                    sortedData.map((row) => 
                        contextRowFactory({
                            row: row,
                            columns: columns,
                            onRowClicked: updateExpandedRow,
                            rowExpanded: expandedRow === row.id,
                        }))
                    }
                </tbody>
            </Table>
        </>
    )
}
