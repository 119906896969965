import { React, useState } from 'react'
import { useNavigate } from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css'

import ContextMenu from './ContextMenu'
import { RemoveTubeModal } from './RemoveTubeModal';
import api from '../util/api';
import { StatusOverlay } from './StatusOverlay';
import { ExpandableContextTable } from './ExpandableContextTable';

// mock user permissions
// to be replaced by the real permissions in future commit
const userPermissions = {
    listTubes: true,
    showTubeDetails: true,
    editTubes: true,
    reorderTubes: true,
    removeTubes: true,
}

/**
 * Applies the specific render function for this column, if defined. 
 * If not defined, the raw value is returned.
 * @param {*} column 
 * @param {*} row 
 * @returns rendered field value
 */
function renderField(column, row) {
    if ('renderFunc' in column) {
        return column.renderFunc(row[column.accessorKey]);
    }
    return row[column.accessorKey];
}

/**
 * Render a row of the tube table with the expandable context menu for this row
 */
function ExpandableTubeTableRow({ row, columns, onRowClicked, rowExpanded, handlers }) {
    return (
        <>
            <tr key={row.id} onClick={() => onRowClicked(row.id)}>
                {columns.map((column) => (
                    <td key={column.id + '.' + row.id} data-testid={'cell-' + column.id}>
                        {renderField(column, row)}
                    </td>
                ))}
            </tr>
            <tr style={{ display: rowExpanded ? 'table-row' : 'none' }}>
                <td colSpan={columns.length}>
                    <ContextMenu
                        tubeId={row.id}
                        showShow={userPermissions.showTubeDetails}
                        onShow={handlers.showClicked}
                        showReorder={userPermissions.reorderTubes}
                        onReorder={handlers.reorderClicked}
                        showEdit={userPermissions.editTubes}
                        onEdit={handlers.editClicked}
                        showRemove={userPermissions.removeTubes}
                        onRemove={handlers.removeClicked}
                    />
                </td>
            </tr>
        </>
    )
}

export default function TubeTable({ columns, tubes, requestTubesUpdate, locations }) {
    const [statusOverlay, setStatusOverlay] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [currentlyRemoving, setCurrentlyRemoving] = useState(null);

    // remove a tube from the database
    function removeTube(reason, location) {
        api.removeTube(currentlyRemoving, reason, location).then(() => {
            setShowRemoveModal(false);
            requestTubesUpdate();
        });
    }

    function showRemoveModalAndSetCurrentlyRemoving(rowId) {
        setCurrentlyRemoving(rowId);
        setShowRemoveModal(true);
    }

    // handler functions for the context menu
    const navigate = useNavigate();

    function showClicked(id) {
        navigate('/show/' + id);
    }

    function editClicked(id) {
        navigate('/edit/' + id);
    }

    function reorderClicked(id) {
        api.getTube(id).then((tube) => {
            api.addToWishlist(tube).then(() => {
                setStatusOverlay({
                    status: 'success',
                    message: 'Ok',
                    timeout: 1500,
                    close: () => { setStatusOverlay(null) }
                });
            }).catch((error) => {
                // TODO: replace this with a prettier error message
                alert("Failed to add tube to wishlist: " + error.message);
            });
        }).catch((error) => {
            // TODO: replace this with a prettier error message
            alert("Failed to fetch tube information: " + error.message);
        });
    }

    function removeClicked(id) {
        showRemoveModalAndSetCurrentlyRemoving(id);
    }

    function createContextRow({ row, columns, onRowClicked, rowExpanded }) {
        return (
            <ExpandableTubeTableRow
                row={row}
                columns={columns}
                onRowClicked={onRowClicked}
                rowExpanded={rowExpanded}
                handlers={{
                    showClicked,
                    reorderClicked,
                    editClicked,
                    removeClicked,
                }}
                key={row.id}
            />
        )
    }

    return (<>
        <StatusOverlay config={statusOverlay} />
        <RemoveTubeModal
            show={showRemoveModal}
            locations={locations}
            close={() => { setShowRemoveModal(false) }}
            onRemove={removeTube}
        />
        <ExpandableContextTable
            columns={columns}
            items={tubes}
            contextRowFactory={createContextRow}
        />
    </>)
}
