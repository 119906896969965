import { FloatingLabel, Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'


/**
 * Renders a labeled input field.
 * @param {String} id - The ID of the input field.
 * @param {String} label - The label of the input field.
 * @param {String} value - The value of the input field.
 * @param {Function} onChange - The function to update the value of the input field.
 * @param {Boolean} disabled - Whether the input field is disabled.
 * @returns {JSX.Element} - The rendered LabeledInput component.
 */
export function LabeledInput({id, label, value, onChange, disabled=false}) {
    return (
        <Form.Group>
            <FloatingLabel label={label} className='mb-3' controlId={id}>
                <Form.Control 
                    type="text" 
                    placeholder='' 
                    onChange={(e) => onChange(e.target.value)} 
                    defaultValue={value} 
                    disabled={disabled} 
                />
            </FloatingLabel>                        
        </Form.Group>
    );
}
