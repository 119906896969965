export const apiHost = process.env.REACT_APP_APIPREFIX || "/";


/**
 * Parses a date string and returns a Date object.
 * @param {string} datestr - The date string to parse.
 * @returns {Date|null} - The parsed Date object, or null if the date string is empty.
 */
function parseDate(datestr) {
    return datestr ? new Date(datestr) : null;
}

/**
 * Formats a tube object.
 * @param {Object} json - The tube object to format.
 * @returns {Object} - The formatted tube object.
 */
function formatTube(json) {
    return {
        id: json.uuid,
        shortName: json.short_name,
        fullName: json.full_name,
        location: json.location,
        orderCode: json.order_code,
        producer: json.producer,
        batch: json.batch,
        orderDate: parseDate(json.ordered_on),
        inventarizationDate: parseDate(json.added_on),
        expirationDate: parseDate(json.expires_on),
        removalDate: parseDate(json.removed_on),
        replacedBy: json.replaced_by,
    };
}

export function createEmptyTube() {
    return {
        id: null,
        shortName: '',
        fullName: '',
        location: '',
        orderCode: '',
        producer: '',
        batch: '',
        orderDate: null,
        inventarizationDate: null,
        expirationDate: null,
        removalDate: null,
        replacedBy: null,
    };
}

/**
 * Retrieves tube data from the API.
 * @param {string} tubeId - The ID of the tube to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the formatted tube object.
 * @throws {Error} - If the API request fails or returns a non-200 HTTP status.
 */
export async function getTube(tubeId) {
    const response = await fetch(`${apiHost}tube/${tubeId}`)
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request tube data: API returned HTTP status " + response.status);
    }
    return formatTube(await response.json());
}

/**
 * Formats a tube type object.
 * @param {Object} json - The raw JSON API response object to format.
 * @returns {Object} - The formatted tube type object.
 */
function formatTubeType(json) {
    return {
        shortName: json.short_name,
        fullName: json.full_name,
        orderCode: json.order_code,
        producer: json.producer,
    };
}

/**
 * Retrieves tube data from the API.
 */
export async function getTubes() {
    const response = await fetch(`${apiHost}tube`)
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request tubes: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatTube);
}

/**
 * Retrieves tube type data from the API.
 * @returns {Promise<Object>} - A promise that resolves to the formatted tube type object.
 * @throws {Error} - If the API request fails or returns a non-200 HTTP status.
 */
export async function getTubeTypes() {
    const response = await fetch(`${apiHost}tube/types`)
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request tube types: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatTubeType);
}

/**
 * Formats a location object.
 * @param {Object} json - The raw JSON API response object to format.
 * @returns {Object} - The formatted location object.
 */
function formatLocation(json) {
    return {
        name: json.name,
    };
}

export async function getLocations() {
    const response = await fetch(`${apiHost}tube/locations`)
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request locations: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatLocation);
}

function serializeDate(date) {
    return date ? date.toISOString().split('T')[0] : null;
}

function serializeTube(tube) {
    return JSON.stringify({
        uuid: tube.id,
        short_name: tube.shortName,
        full_name: tube.fullName,
        location: tube.location,
        order_code: tube.orderCode,
        producer: tube.producer,
        batch: tube.batch,
        ordered_on: serializeDate(tube.orderDate),
        added_on: serializeDate(tube.inventarizationDate),
        expires_on: serializeDate(tube.expirationDate),
        removed_on: serializeDate(tube.removalDate),
        replaced_by: tube.replacedBy,
    })
}

/**
 * Search tube database
 * @param {string} searchterm 
 * @param {string} location 
 * @param {boolean} hideInactive 
 * @param {boolean} hideRemoved 
 * @param {Date} expiresBefore 
 * @returns {List[Object]}
 */
export async function searchTubes(
    searchterm='', 
    location='',
    hideExpired=true,
    hideRemoved=true,
    expiresBefore=null,
    ) {
    let searchParams = {}
    if (searchterm) {
        searchParams.searchterm = searchterm;
    }
    if (location) {
        searchParams.location = location;
    }
    // will be overriden when expiresBefore is set
    if (hideExpired) {
        searchParams.expires_on = '>=' + new Date(Date.now()).toISOString().split('T')[0];
    }
    if (hideRemoved) {
        searchParams.removed = 'hide';
    }
    
    if (expiresBefore) {
        searchParams.expires_on = '<=' + serializeDate(expiresBefore);
    }
    const response = await fetch(`${apiHost}tube/?` + new URLSearchParams(searchParams), { method: 'GET' })
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request tube data: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatTube);
}

export async function updateTube(tube) {
    const response = await fetch(`${apiHost}tube/${tube.id || ''}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: serializeTube(tube),
    });
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to update tube data: API returned HTTP status " + response.status);
    }
    let parsedJson = await response.json();
    return {
        id: parsedJson.uuid,
    };
}

/**
 * Remove a tube (i.e. set removedOn date & update location).
 * @param {int} tubeId Identifier of the tube to be removed
 * @param {str} reason Human-readable reason for the removal
 * @param {str} location New location of the tube
 * @param {int|null} replacedBy Identifier of the tube that replaces the removed tube, or null if none
 */
export async function removeTube(tubeId, reason, location, replacedBy=null) {
    // fetch tube data
    const tube = await getTube(tubeId);
    // update tube
    tube.removalDate = new Date();
    const oldLocation = tube.location;
    tube.location = location;
    tube.replacedBy = replacedBy;
    const updatedTube = await updateTube(tube);
    // add explanatory comment
    const comment = `Feld location aktualisiert (war: ${oldLocation}). Grund: Prüfröhrchen entfernt (${reason})`;
    await addComment(tubeId, comment);
    return updatedTube;
}

/**
 * Format a comment object from the API to a comment object for the frontend.
 * @param {Object} json API response object
 * @returns {Object} formatted comment object
 */
function formatComment(json) {
    return {
        tubeId: json.tube_id,
        author: json.author,
        date: new Date(json.date),
        text: json.text,
    }
}

/**
 * Retrieves comments for a tube from the API.
 * @param {int} tubeId tube ID for which to retrieve comments
 * @throws {Error} - If the API request fails or returns a non-200 HTTP status.
 * @returns {list[Object]} - A list of formatted comment objects.
 */
export async function getComments(tubeId) {
    const response = await fetch(`${apiHost}tube/${tubeId}/comments`);
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request comments: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatComment);
}

/**
 * Push a new comment to the API.
 * @param {int} tubeId ID of the tube to add the comment to
 * @param {Object} text Text of the comment to add to the API (author and date are added by the server)
 * @returns {Object} The formatted comment object returned by the API
 */
export async function addComment(tubeId, text) {
    const reqData = new FormData();
    reqData.append('text', text);
    // Debug
    const response = await fetch(`${apiHost}tube/${tubeId}/comments`, {
        method: 'POST',
        body: reqData,
    });
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to add comment: API returned HTTP status " + response.status);
    }
    return formatComment(await response.json());
}

function serializeWishlistRequest(tube) {
    return JSON.stringify({
        short_name: tube.shortName,
        location: tube.location,
        replaces: tube.id,
    });
}

function formatWishlistItem(json) {
    return {
        id: json.id,
        shortName: json.short_name,
        fullName: json.full_name,
        location: json.location,
        orderCode: json.order_code,
        producer: json.producer,
        addedBy: json.added_by,
        addedOn: new Date(json.added_on),
        replaces: json.replaces || null,
    };
}

/**
 * Add a new item to the wishlist.
 * @param {*} tube tube object from which the wishlist item is created
 */
export async function addToWishlist(tube) {
    const response = await fetch(`${apiHost}wishlist/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: serializeWishlistRequest(tube),
    });
    if (!response.ok || response.status !== 201) {
        throw new Error("Failed to add tube to wishlist: API returned HTTP status " + response.status);
    }
}

/**
 * Remove an item from the wishlist.
 * @param {*} wishId 
 */
export async function removeFromWishlist(wishId) {
    const response = await fetch(`${apiHost}wishlist/${wishId}`, {
        method: 'DELETE',
    });
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to remove tube from wishlist: API returned HTTP status " + response.status);
    }
}

/**
 * Fetch the wishlist from the API.
 * @returns list of wishlist items
 */
export async function getWishlist() {
    const response = await fetch(`${apiHost}wishlist/`);
    if (!response.ok || response.status !== 200) {
        throw new Error("Failed to request wishlist: API returned HTTP status " + response.status);
    }
    return (await response.json()).map(formatWishlistItem);
}

const api = {
    apiHost,
    addComment,
    getComments,
    getTube,
    searchTubes,
    getTubeTypes,
    getLocations,
    updateTube,
    removeTube,
    createEmptyTube,
    addToWishlist,
    removeFromWishlist,
    getWishlist,
}

export default api;
