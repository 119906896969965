import './header.scss';


function Header() {
  return (
    <header>
        <div className="logo"></div>
        <a href='/'>
            <h1>Prüfröhrchen Verwaltung <br/>ABC-Zug München-Land</h1>
        </a>
    </header>
  );
}

export default Header;
