import { create } from 'zustand';
import api from './api.js';

// Defined roles to be used for permission management in the app
const knownRoles = {
    dataExporter: 'data_exporter',
    tubeAdmin: 'tube_admin',
    tubeEditor: 'tube_editor',
    tubeReader: 'tube_reader',
    wishlistEditor: 'wishlist_editor',
    wishlistReader: 'wishlist_reader',
}

/**
 * Represents a user's information.
 * @typedef {Object} UserInfo
 * @property {string} username - The username of the user.
 * @property {string} displayName - The display name of the user.
 * @property {string[]} roles - The roles assigned to the user.
 */
function UserInfo(username = '', displayName = '', roles = [], expires = null) {
    return {
        username: username,
        displayName: displayName,
        roles: roles,
        expires: expires || new Date(),
    }
}

async function getUserInfo() {
    let response = await fetch(api.apiHost + "auth/userinfo")
    if (response.status === 200) {
        const json = await response.json()
        return UserInfo(
            json.name,
            json.display_name,
            json.roles,
            json.expires,
        )
    } else {
        throw new Error("received http status " + response.status + " from server");
    }
}

const useAuthStore = create(set => ({
    UserInfo: null,
}))

/**
 * Authenticates the user and retrieves user information.
 * User information is stored in the global state and updated only when the expiration date is reached.
 * @param {function} callback - The callback function to handle the authentication result.
 * @returns {Promise<void>} - A promise that resolves when the authentication is completed.
 */
async function authenticate() {
    // verify user information from global app state
    const userInfo = useAuthStore.getState().UserInfo;
    if (userInfo && userInfo.expires > new Date()) {
        return userInfo;
    }
    // verify user information from server (may lead to a redirect to login page)
    else {
        // try to fetch user information from the server
        // if that fails, redirect to the login page
        try {
            const newUserInfo = await getUserInfo();
            useAuthStore.setState({ UserInfo: newUserInfo });
            return newUserInfo;
        } catch (e) {
            window.location.replace(api.apiHost + "auth/login");
            return;
        }
    }
}

/**
 * Check if the user has all requested roles. Calls auth.authenticate underneath, and may therefore 
 * redirect to the login page if no user token is present.
 * @param {array} roles 
 * @returns {Boolean} True if the user has all requested roles, false if not
 */
async function hasRoles(roles) {
    const userInfo = await authenticate();
    // no userInfo object = no successful login has happend
    if (!userInfo) {
        return false;
    }
    // test if the user has all requested roles
    for (let role of roles) {
        if (!userInfo.roles.includes(role)) {
            return false;
        }
    }
    return true;
}

export async function hasRoleDataExporter() {
    return await hasRoles([knownRoles.dataExporter]);
}

export async function hasRoleTubeAdmin() {
    return await hasRoles([knownRoles.tubeAdmin]);
}

export async function hasRoleTubeEditor() {
    return await hasRoles([knownRoles.tubeEditor]);
}

export async function hasRoleTubeReader() {
    return await hasRoles([knownRoles.tubeReader]);
}

export async function hasRoleWishlistEditor() {
    return await hasRoles([knownRoles.wishlistEditor]);
}

export async function hasRoleWishlistReader() {
    return await hasRoles([knownRoles.wishlistReader]);
}

const module = {
    authenticate,
    hasRoles,
    hasRoleDataExporter,
    hasRoleTubeAdmin,
    hasRoleTubeEditor,
    hasRoleTubeReader,
    hasRoleWishlistEditor,
    hasRoleWishlistReader,
    useAuthStore,
}

export default module;
