import { FloatingLabel, Form} from 'react-bootstrap';
import { LabeledInput } from './LabeledInput';
import 'bootstrap/dist/css/bootstrap.min.css'


/**
 * Returns the label for the given value
 * this is necessary because in a select, the value is the key,
 * but in read-only mode, the label needs to be used as
 * @param {*} value 
 * @param {*} options 
 */
function getLabel(value, options) {
    const option = options.find(option => option.key === value);
    return option ? option.label : value;
}

/**
 * Renders a labeled select.
 * @param {String} id - The ID of the date picker.
 * @param {String} label - The label of the date picker.
 * @param {Date} value - The value of the date picker.
 * @param {Function} onChange - The function to update the value of the date picker.
 * @param {Array} options - The options of the select.
 * @param {Boolean} disabled - Whether the date picker is disabled.
 * @returns {JSX.Element} - The rendered LabeledDatePicker component.
 */
export function LabeledSelect({id, label, value, onChange, options, disabled=false}) {
    if (disabled === true) {
        return LabeledInput({id, label, value: getLabel(value, options), onChange, disabled});
    }
    return (
        <Form.Group>
            <FloatingLabel label={label} className='mb-3' controlId={id}>
                <Form.Select aria-label={label} onChange={(e) => onChange(e.target.value)} value={value} disabled={disabled}>
                    {options.map((option) => (
                        <option key={option.key} value={option.key}>{option.label}</option>
                    ))}
                </Form.Select>
            </FloatingLabel>
        </Form.Group>
    );
}
