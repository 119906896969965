import * as api from '../util/api';
import * as globals from '../util/globals';

import TubeTable from '../components/TubeTable'
import SearchBar from '../components/SearchBar';
import PreferenceBar from '../components/PreferenceBar';
import Header from '../components/header';
import Footer from '../components/Footer';
import InfoBar from '../components/InfoBar';
import { hasRoleTubeReader } from '../util/auth';

import './Show.scss'
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

const COLUMNS = [
    {
        id: 'shortName',
        Header: 'Typ',
        accessorKey: 'shortName',
    },
    {
        id: 'fullName',
        Header: 'Bezeichnung',
        accessorKey: 'fullName',
    },
    {
        id: 'location',
        Header: 'Ort',
        accessorKey: 'location',
    },
    {
        id: 'expiresOn',
        Header: 'Ablaufdatum',
        accessorKey: 'expirationDate',
        renderFunc: (date) => {
            try {
                return date.toLocaleDateString('de-DE', {
                    year: '2-digit',
                    month: 'short',
                });
            } catch (e) {
                return "ERROR";
            }
        }
    },
];

export default function Show() {
    const columns = COLUMNS;
    const [tubes, setTubes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [textFilter, setTextFilter] = useState('');
    const [hideExpired, setHideExpired] = useState(false);
    const [hideRemoved, setHideRemoved] = useState(true);
    const [error, setError] = useState('');

    let { location, expiresBefore } = useParams();
    expiresBefore = expiresBefore ? new Date(expiresBefore) : null;

    // update the text filter, then re-query the API
    function updateTextFilter(value) {
        setTextFilter(value);
        updateTubes();
    }

    // update tubes from API
    function updateTubes() {
        api.searchTubes(
            textFilter,
            location,
            hideExpired,
            hideRemoved,
            expiresBefore,
        )
            .then(setTubes)
            .catch((error) => {
                setError("Fehler beim Laden der Daten: " + error.message);
            });
    }

    // code to run when the component is first rendered
    useEffect(() => {
        hasRoleTubeReader().then(() => {
            updateTubes(
                textFilter,
                hideExpired,
                hideRemoved,
            );
        }).catch(() => {
            setError("Sie haben keine Berechtigung, diese Seite zu sehen.");
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideExpired, hideRemoved, textFilter]);

    useEffect(() => {
        globals.getLocations().then(setLocations);
    }, []);

    return (
        <div className="show">
            <Header />
            <div className="content">
                <InfoBar show={error !== ""} text={error} title="Fehler" color="#F00" />
                <PreferenceBar hideExpired={hideExpired} setHideExpired={setHideExpired} hideRemoved={hideRemoved} setHideRemoved={setHideRemoved} />
                <SearchBar textFilter={textFilter} setTextFilter={updateTextFilter} />
                <TubeTable columns={columns} tubes={tubes} requestTubesUpdate={updateTubes} locations={locations} />
            </div>
            <Footer />
        </div>
    )
}